import dynamic from 'next/dynamic';
import { useAuth0 } from '@auth0/auth0-react';

import PropTypes from 'prop-types';
import Layout from '../components/common/Layout';
import {
  fetchDataCitiesForMarketIndexPage,
  getServerSidePropsFromHomePage,
} from '../utils/page';

const Cookie = dynamic(import('../components/common/Cookie'));
const Home = dynamic(import('../components/Home'));
const Mietpolizei = dynamic(import('../components/Mietpolizeit'));

const HomePage = (props) => {
  const { wildcard } = props;
  const { user } = useAuth0();
  return (
    <>
      {!user && !wildcard?.length && (
        <>
          <Cookie />
          <Layout
            Layout
            title="Was wohnen wirklich kostet - wohnpreis.de"
            description="Wohnpreis.de ist das erste Vergleichs- und Preisportal für Immobilien, das eine
      plattformübergreifende Angebotsauswertung von Wohnimmobilien anbietet."
          >
            <Home {...props} />
          </Layout>
        </>
      )}
      {wildcard?.length && (
        <>
          <Mietpolizei />
        </>
      )}
    </>
  );
};

export async function getServerSideProps(context) {
  let wildcard = context.req.headers.host.split('.')[0];
  wildcard = wildcard === 'mietmonitor' ? 'mietmonitor' : null;
  const resultData = await getServerSidePropsFromHomePage();
  const options = await fetchDataCitiesForMarketIndexPage();

  return {
    props: { resultData, options, wildcard },
  };
}

HomePage.propTypes = {
  wildcard: PropTypes.string,
};

HomePage.defaultProps = {
  wildcard: '',
};

export default HomePage;
